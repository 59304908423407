import React from 'react'

const ServiceMobileTags = () => (
  <section className="service-mobile bg-faded py-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-1">
            <span className="text-muted seo-regular">Entwicklung mobiler Apps </span>
            <br />
            <strong>Kosten</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="row">
            <div className="col-sm-4">
              <strong>Offshoring Preisgestaltung</strong>
              <br />
              Hohe Qualität mit niedrigem Preis
            </div>
            <div className="col-sm-4">
              <strong>Time & Materials</strong>
              <br />
              Zahlung in bequemen Tranchen
            </div>
            <div className="col-sm-4">
              <strong>GRATIS</strong>
              <br />
              Kostenlose Schätzung Ihrer Idee
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Mobiler Apps</span>
            <br />
            Entwicklung
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <ul className="list-unstyled list-inline list-badges lead">
            <li>
              <span className="badge badge-light">Mobile strategie</span>
            </li>
            <li>
              <span className="badge badge-light">Geschäftsanalyse</span>
            </li>
            <li>
              <span className="badge badge-light">
                Bestes mobiles Anwendungsdesign
              </span>
            </li>
            <li>
              <strong className="badge badge-light">
                Prototyping von Apps
              </strong>
            </li>
            <li>
              <span className="badge badge-light">
                Cloud- und API-Integration
              </span>
            </li>
            <li>
              <strong className="badge badge-light">
                Mobile app development
              </strong>
            </li>
            <li>
              <span className="badge badge-light">QA-tests</span>
            </li>
            <li>
              <span className="badge badge-light">Veröffentlichung im App Store</span>
            </li>
            <li>
              <span className="badge badge-light">Wartung und Support</span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4" />
        <div className="col-sm-12 col-md-8" />
      </div>
    </div>
  </section>
)

export default ServiceMobileTags
