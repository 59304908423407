import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import smoothScroll from 'scroll-to-element'

const ServiceMobileCta = () => (
  <section className="service-mobile cto">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <h3 className="seo-span seo-strong text-muted">
              Maßgeschneiderte mobile Apps 
            </h3>
            <br />
            <span className="text-service">
              für iOS und Android
            </span>
            .<br />
            <a
              href="#contact-partial"
              data-form-category="mobileapps"
              className="btn btn-outline-extra1 my-2"
              id="custom-mobile-app-dev-request-btn"
              onClick={event => {
                event.preventDefault()
                smoothScroll('#contact-partial', { duration: 500 })
              }}
            >
              Nach Angebot fragen
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Arbeiten Sie mit einem Team von{' '}
              <strong className="seo-regular"> Android App-Entwicklern </strong> und Kotlin-Spezialisten
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Mieten Sie  <strong className="seo-regular">iOS-Entwickler,</strong>{' '}
              die Swift beherrschen
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Entwickeln Sie eine Windows-App in .NET
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Entwickeln Sie eine Mobil-, Web- und Hybrid-App
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Erhalten Sie eine funktionale und schöne UI / UX
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
  </section>
)

export default ServiceMobileCta
