import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faApple,
} from '@fortawesome/fontawesome-free-brands'
import Link from '../../common/Link'

const ServiceMobileOffer = () => (
  <section className="service-mobile">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Mobile App-Lösungen</span>
            <br />
            für Unternehmen
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group text-center">
            <div className="card">
              <div className="card-body">
                <h3>Mobile development outsourcing</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Um herauszufinden, welches Modell für Sie ideal wäre,{' '}
                  <Link to="/services/poland-software-outsourcing/">
                  schauen Sie sich unseren Outsourcing-Leitfaden an.
                  </Link>
                  .
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>VERWANDELN SIE IHRE WEBSITE IN EINE MOBILE APP</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Machen Sie den nächsten Schritt und verwandeln Sie Ihre aktuelle Plattform in eine funktionsfähige App.

                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>MASSGESCHNEIDERTE MOBILE APPS</h3>
                <p className="text-sm text-muted text-italic mt-2">
                  Wir sind bereit, eine App jeglicher Art zu entwickeln.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Mobile Application</span>
            <br />
            <strong>Design - Lösungen</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8 pt-2">
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faApple} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">ENTWICKLUNG VON IOS APPS</strong>
              <p className="text-muted">
                Abgestimmt auf die Bedürfnisse von iPhones, iPads und Apple Watches,
               um die Aufmerksamkeit der Kunden im App Store zu erregen,
               die auch die anspruchsvollsten Benutzer zufrieden stellen werden.

              </p>
            </div>
          </div>
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faAndroid} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">ENTWICKLUNG VON ANDROID APPS</strong>
              <p className="text-muted">
              Beibehaltung einer konsistenten und effizienten Benutzeroberfläche im Android-Ökosystem mit dem Ziel,
               die Bedürfnisse der größten Gruppe von Smartphone-Nutzern zu erfüllen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceMobileOffer
