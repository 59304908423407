import React from 'react'

const MvpReasons = () => (
  <section className="section bg-faded">
    <div className="container">
      <h2 className="text-center mt-2">
        Statten Sie Ihr Startup mit Full-Stack-Ingenieuren aus
      </h2>
      <div className="card-group card-group-transparent my-2 mvp-reasons">
        <div className="card">
          <div className="card-body">
            Schnelles Prototyping -
            <br />
            <em className="text-muted">
              <abbr title="Minimum Viable Product">MVP</abbr> wird in 12-16 Wochen geliefert
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            Volle Teamunterstützung:
            <br />
            <em className="text-muted">
              Analysten, PM, Entwickler, Designer, Tester
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            Schöne und benutzerfreundliche UI/UX, 
            <em className="text-muted">
            von unseren Designern gestaltet
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h3 className="seo-strong m-0">Agile Programmierung</h3>
            <br />
            <em className="text-muted">verwaltet mit SCRUM-Prinzipien</em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Langfristige Unterstützung
            <br />
            <em className="text-muted">
              - Fortsetzung der Produktentwicklung über die MVP-Periode hinaus
            </em>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
          Outsourcing-freundliche
            <br />
            <em className="text-muted">
              Fernverwaltung – ​​barrierefreie Programmierung
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default MvpReasons
